.center {
	text-align: center;
}
body {
	margin: 0px;
	padding: 0px;
}
button {
	cursor: pointer;
}
.body-wrapper {
	display: flex;
	background-color: #f5f5f5;
	font-family: "Roboto";
}
.left-menu {
	padding: 20px 20px 20px 20px;
	background-color: white;
	height: 100vh;
	width: 300px;
	flex: 300px;
}
.grid {
	height: 100%;
	display: grid;
	vertical-align: middle;
	align-items: center;
}
header {
	background-color: white;
	padding: 25px 35px;
}
header h1 {
	font-size: 28px;
	font-weight: 500;
	margin: 0px;
}
.main-space {
	flex: 0 0 calc(100% - 300px);
	max-width: calc(100% - 300px);
}
.content {
	height: calc(100vh - 83px);
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto;
}
.nav {
	padding: 0px;
	margin: 0px;
	margin-top: 35px;
	list-style: none;
	height: calc(100% - 80px);
	overflow-x: hidden;
	overflow-y: auto;
}

.nav > li > a {
	position: relative;
	margin-bottom: 10px;
	border-radius: 20px;
	font-size: 1.125rem;
	display: block;
	padding: 20px 35px 20px 55px;
	color: #bfbfbf;
	text-decoration: none;
}

.nav > li.active > a {
	background-color: #3a0ca3;
	color: white !important;
}
.nav > li > a:hover {
	color: #3a0ca3;
}
.nav > li ul {
	margin-left: 30px;
	list-style: none;
	padding: 0px;
	margin-bottom: 15px;
}
.nav > li ul li a {
	color: #bfbfbf;
}
.nav > li ul li:hover a {
	color: #3a0ca3;
}
.nav > li ul li {
	position: relative;
	padding-left: 25px;
	padding: 10px 10px 10px 25px;
	transition: 0.65s ease;
}
.nav > li ul li::before {
	content: " ";
	width: 7px;
	height: 2px;
	background-color: #bfbfbf;
	display: block;
	position: absolute;
	left: 0;
	top: calc(50% - 1px);
	transition: 0.65s ease;
}
.nav > li ul li:hover::before {
	width: 15px;
	background-color: #3a0ca3;
}
.nav > li ul li:hover {
	padding: 10px 10px 10px 30px;
}
.nav > li ul li a {
	text-decoration: none;
}

.nav > li ul li.active a {
	color: #3a0ca3;
}
.nav > li ul li.active::before {
	background-color: #3a0ca3;
}

/* icon ------ */

.nav > li > a .icon * {
	fill: #bfbfbf;
}
.nav > li > a:hover .icon * {
	fill: #3a0ca3;
}
.nav > li.active > a .icon * {
	fill: #fff;
}
.nav > li > a .icon {
	position: absolute;
	top: calc(50% - 11px);
	left: 20px;
}

/* arrow ------ */

.arrow {
	position: absolute;
	top: calc(50% - 15px);
	right: 20px;
}
.nav > li > a .arrow path {
	fill: #bfbfbf;
}
.nav > li > a:hover .arrow path {
	fill: #3a0ca3;
}
.nav > li.active > a .arrow path {
	fill: #fff;
}
.nav > li > a .arrow {
	position: absolute;
	top: calc(50% - 12px);
	right: 10px;
}

/* -------------------------------- */

header {
	box-shadow: 0 5px 3px rgba(0, 0, 0, 0.05);
}
.block-wrapper {
	background-color: white;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: 0 5px 3px rgba(0, 0, 0, 0.05);
}
.table-wrapper {
	overflow-x: auto;
	max-width: 100%;
}
.list-table {
	width: 100%;
	min-width: 1000px;
}
table {
	border-collapse: collapse;
}
.list-table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.list-table tbody td,
.list-table thead th {
	padding: 10px;
	font-weight: 400;
	text-align: left;
}
.list-table tbody tr:nth-child(2n) {
	background-color: rgba(0, 0, 0, 0.03);
}
.right {
	text-align: right;
}
.sform {
	position: relative;
}
.sform input {
	font-size: 15px;
	padding: 14px 40px 10px 20px;
	font-weight: 200;
	width: 100%;
	border: none;
}
.sform button {
	position: absolute;
	right: 0;
	width: 44px;
	height: 44px;
	background: none;
	border: none;
}
.btn {
	color: white;
	height: 42px;
	margin-top: 15px;
	margin-left: 10px;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 15px;
}

.pagination {
	display: inline-block;
}
.pagination > div {
	margin: 0px 3px;
	overflow: hidden;
	width: 40px;
	border-radius: 5px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.pagination > div a {
	display: block;
	width: 40px;
	height: 40px;
	text-decoration: none;
	color: black;
}
.pagination > div:hover {
	cursor: pointer;
	color: white;
	background-color: #3a0ca3;
}
.nactive {
	background-color: #3a0ca3;
	color: white;
}

h1 svg {
	position: relative;
	top: 5px;
	margin-right: 20px;
	display: none;
}
.date-filter select {
	width: 100%;
	font-size: 15px;
	border: 1px solid #bfbfbf;
	border-radius: 10px;
	padding: 10px 15px;
}
.report-item {
	background-color: white;
	border-radius: 10px;
	margin-bottom: 20px;
	cursor: pointer;
}
.report-item div {
	display: block;
	color: #bfbfbf;
	text-decoration: none;
	padding: 0px 20px;
	line-height: 60px;
}
.report-item svg {
	position: relative;
	top: 4px;
	margin-right: 20px;
}
.report-item a:hover {
	color: #3a0ca3;
}
.report-item a:hover svg path {
	fill: #3a0ca3;
}

@media (max-width: 760px) {
	.date-filter select {
		margin: 5px 0px;
	}
	h1 svg {
		display: inline-block;
	}
	.btn {
		margin-top: 10px;
		padding: 10px;
	}
	.table-wrapper {
		margin-top: 30px;
	}
	.left-menu {
		left: -100%;
		top: 0;
		width: 300px;
		position: fixed;
		z-index: 2;
	}
	.main-space {
		max-width: 100%;
		flex: 0 0 100%;
	}
}

.product-name {
	display: flex;
}
.product-name .img {
	flex: 0 0 100px;
}
.product-name .desc {
	flex: 0 0 calc(100% - 100px);
	padding-left: 20px;
}
.product-name .img > div {
	padding-top: 100%;
	background-position: center;
	background-size: cover;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}
.bread *,
.bread a,
.bread span {
	color: black;
	margin-left: 7px;
	margin-right: 7px;
}
.desc-wrapper p span {
	font-weight: bold;
}
.desc-wrapper p {
	margin: 10px 0px;
	font-size: 16px;
	line-height: 1.5;
}
.desc-wrapper a {
	color: #3a0ca3;
	text-decoration: none;
}

.table-wrapper > h3 {
	margin-top: 0px;
	font-size: 22px;
	font-weight: 400;
	padding-top: 5px;
	padding-bottom: 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 0px;
}
.product-name h3 {
	margin: 0px;
	font-size: 20px;
}
.product-name p {
	color: #989898;
	font-size: 15px !important;
	margin-top: 0px !important;
}

.project-desc {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 25px;
	margin-top: 25px;
}
.project-desc h4 {
	font-size: 20px;
	font-weight: 500;
	margin-top: 0px;
	margin-bottom: 0px;
}

/* -------------------------------- */

.login {
	height: 100vh;
	width: 100%;
	position: relative;
}
.form-wrapper {
	position: absolute;
	padding: 25px 20px 20px 20px;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	width: 400px;
	background-color: white;
	border-radius: 10px;
}
.form-wrapper h1 {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 25px;
	font-size: 26px;
	font-weight: 400;
}
.form-wrapper label {
	width: 100%;
	display: block;
	margin-bottom: 15px;
}
.form-wrapper label input {
	width: 100%;
	font-size: 16px;
	padding: 10px 15px;
	border-radius: 5px;
	background-color: #f5f5f5;
	border: 1px solid #d1d1d1;
}
.form-wrapper button {
	color: white;
	height: 42px;
	padding: 10px 35px;
	border: none;
	border-radius: 5px;
	font-size: 15px;
	background-color: #3a0ca3;
}

.exit-wrapper {
	text-align: right;
}
.exit-wrapper button {
	cursor: pointer;
	height: 33px;
	border: 2px solid rgb(255, 70, 70);
	background-color: white;
	font-size: 15px;
	padding: 0px 20px;
	color: rgb(255, 70, 70);
	border-radius: 10px;
}

.popup-msg {
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 999;
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
}

.popup-msg > div {
	max-height: 98vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
	border-radius: 4px;
	width: 400px;
	max-width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
}
.popup-msg > div .close {
	text-align: right;
}

.popup-msg > div svg {
	width: 30px;
	cursor: pointer;
	height: 30px;
}

.filter-wrapper {
	display: flex;
}
.filter-wrapper > form {
	width: calc(100% - 30px);
}
.open-filter {
	width: 30px;
	text-align: center;
}
.open-filter svg {
	margin-top: 8px;
	cursor: pointer;
}
.filter-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.3);
}
.filter-popup > div {
	width: 500px;
	max-width: 100%;
	max-height: 98vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
	border-radius: 10px;
	padding: 15px;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
.filter-popup .close {
	text-align: right;
	margin-bottom: 15px;
}
.filter-popup button {
	background-color: #3a0ca3;
	color: white;
	font-size: 15px;
	padding: 8px 20px;
	border-radius: 5px;
}
.filter-popup label {
	display: block;
	font-size: 15px;
	margin-bottom: 15px;
}
.filter-popup label select,
.filter-popup label input {
	width: 100%;
	margin-top: 7px;
	font-size: 14px;
	padding: 5px 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.filter-popup label input[type="checkbox"] {
	width: auto;
	margin-top: 0px;
	position: relative;
	top: 1px;
	margin-right: 10px;
}

.dropmenu__wrapper {
	position: relative;
}
.dropmenu {
	right: 0;
	position: absolute;
	background-color: white;
	z-index: 2;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 0px 5px;
	display: none;
}
.dropmenu ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
.dropmenu ul li {
	cursor: pointer;
	padding: 10px 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dropmenu__wrapper:hover .dropmenu {
	display: block;
}

.popup-integration {
	background-color: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}
.popup-integration > div {
	width: 500px;
	max-width: 100%;
	max-height: 98vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
	border-radius: 10px;
	padding: 15px;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
.popup-integration select,
.popup-integration textarea,
.popup-integration input {
	width: 100%;
	margin-top: 5px;
	font-size: 14px;
	padding: 10px 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	resize: none;
}
.popup-integration textarea {
	height: 100px;
}
.popup-integration label {
	margin-bottom: 15px;
	margin-top: 5px;
	display: block;
}

.popup-integration button {
	background-color: #3a0ca3;
	color: white;
	font-size: 15px;
	padding: 8px 20px;
	border-radius: 5px;
}
.type-sellect {
	position: relative;
}
.type-sellect__text {
	overflow: hidden;
	width: 100%;
	background-color: white;
	color: black;
	margin-top: 5px;
	margin-bottom: 0px;
	padding: 0px 10px;
	border-radius: 0px;
	height: 38px;
	line-height: 38px;
	font-size: 13px;
	background-image: url("../image/arrow-sellect.svg");
	background-size: 9px;
	background-repeat: no-repeat;
	background-position: right 3px center;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.type-sellect__options {
	border: 1px solid rgba(0, 0, 0, 0.2);
	position: absolute;
	width: 100%;
	background-color: white;
	margin-top: -25px;
	padding: 0px 10px;
}
.type-sellect__options label {
	cursor: pointer;
	color: black;
	display: block;
	padding: 5px;
}
.new-program .form-item__wrapper .type-sellect__item input {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	margin-right: 10px;
}
.type-sellect__item {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	padding: 10px 5px !important;
}
.type-sellect__item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.type-sellect__options input {
	width: auto;
}

.popup-error {
	background-color: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}
.popup-error > div {
	width: 500px;
	max-width: 100%;
	max-height: 98vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
	border-radius: 10px;
	padding: 15px;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
.popup-error > div h3 {
	text-align: center;
	font-size: 18px;
}
.popup-error > div .btn-wrapper {
	text-align: center;
}
.popup-error > div .btn-wrapper button {
	cursor: pointer;
	height: 33px;
	background-color: #3a0ca3;
	font-size: 15px;
	padding: 0px 20px;
	color: white;
	border: none;
	margin: 0px 5px;
	border-radius: 10px;
}

/* -------------------------------- */

.status-head {
	width: 100%;
	margin-top: 7px;
	font-size: 14px;
	padding: 8px 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-image: url("../image/arrow-down.svg");
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: right center;
	cursor: pointer;
}
.status-body {
	position: absolute;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.2);
	width: calc(100% - 30px);
	z-index: 2;
	height: 125px;
	overflow-x: hidden;
	overflow-y: auto;
}
.status-body > div {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding: 5px 10px 5px 20px;
	cursor: pointer;
	background-image: url("../image/checkbox.svg");
	background-size: 14px;
	background-position: left 3px center;
	background-repeat: no-repeat;
}
.status-body > div.active {
	background-image: url("../image/checkboxcheck.svg");
}

.date-filter input {
	width: 100%;
	padding: 10px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin-top: 5px;
}
.date-filter button {
	background: rgb(32, 116, 68);
	cursor: pointer;
	color: white;
	height: 40px;
	margin-top: 24px;
	margin-left: 10px;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 15px;
}
